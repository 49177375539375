<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.real_estate_title')"
              label-for="real_estate_title"
            >
              <b-form-input
                id="real_estate_title"
                :value="searchFields.real_estate_title"
                @input="inputChange($event, 'real_estate_title')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.real_estate_ad_number')"
              label-for="real_estate_ad_number"
            >
              <b-form-input
                id="real_estate_ad_number"
                :value="searchFields.real_estate_ad_number"
                @input="inputChange($event, 'real_estate_ad_number')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.applicant name')"
              label-for="customer_name"
            >
              <b-form-input
                id="customer_name"
                :value="searchFields.customer_name"
                @input="inputChange($event, 'customer_name')"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <TableIndex
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'real-estate-advertisement-offers',
      singularName: 'real-estate-advertisement-offers',
      searchFields: {
        real_estate_title: null,
        real_estate_ad_number: null,
        customer_name: null,
      },
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
  },
}
</script>
